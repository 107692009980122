.currentOrderListCol{
  /* border:1px solid black; */
  margin-top: 20px;
}
.currentOrderCollapse{
  /* width:100%; */
  /* background: black; */
}
.currentOrder{
  
  margin: 0 auto;
  /* min-width:300px; */
 
}
.currentOrder .currentOrderHeader .info{
  /* border:1px solid black; */
  /* min-height: 50px; */
  padding:0px;
  font-weight: 500;
 
}

.currentOrder  .ant-collapse-header{
  padding: 12px 16px !important;
  background: white;
}
.currentOrder .ant-collapse-content{
  /* background: white !important; */
 
}
