.shopContainer {
  /* border:1px solid black; */
  margin: 20px;
  min-height: 400px;
  background: white;
  box-shadow: 3px 6px 16px rgba(221, 221, 221, 0.2);
  padding: 25px;
  /* position: relative; */
}

.shopContainer .noAddressCover {
  position: fixed;

  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.733);

  z-index: 98;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.shopContainer .noAddressCover .alert {
  position: fixed;
  bottom: 0px;
  min-height: 200px;
  padding: 40px 30px;
  width: 100%;
  background: rgba(43, 43, 43, 0.959);
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-family: 'montserrat';
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(43, 43, 43, 0.959);
  /* border-radius: 2em; */
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-family: 'montserrat';
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dishBox {
  height: 240px;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.smallDishBox {
  height: 110px;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.smallDishBox:hover {
  transition: 0.2s ease-in all;
  transform: scale(1.03);
  box-sizing: border-box;
}

.smallDishBox .info {
  position: absolute;
  padding: 10px 15px;
  color: white;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 110px;
  background: rgba(0, 0, 0, 0.644);
}

.dishBox .dishImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border:1px solid red; */
  background: rgba(255, 0, 0, 0.356);
}
.dishBox .dishImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; /* Center the image within the element */
}

.dishBox:hover {
  /* box-shadow: 3px 3px 5px rgba(250, 85, 79, 0.295); */
  transition: 0.2s ease-in all;
  /* border:3px solid transparent; */
  transform: scale(1.03);
  box-sizing: border-box;
}

.dishBox .info {
  position: absolute;
  padding: 10px 15px;
  color: white;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  background: rgba(0, 0, 0, 0.644);
}
.info .right {
  /* border:1px solid white; */
  font-size: 0.9em;
  word-wrap: none;
  text-align: right;
  white-space: nowrap;
}

.orderButton {
  color: rgb(250, 190, 79);
}
.fromStore {
  margin-top: 5px;
  font-size: 0.7em;
  color: rgb(175, 175, 175);
}

.scrollAbleAddressSelection {
  /* background: red !important; */
  border: 1px solid rgb(238, 238, 238) !important;
  /* border-radius: 5px; */
}
.shopInfo .scrollAbleAddressSelection .time {
  font-weight: 700;
  padding: 0px 8px;
  text-align: left;
}
.scrollAbleAddressSelection .ant-list {
  padding: 5px 5px;
  border-radius: 0px;
}

.scrollAbleAddressSelection .ant-list-item {
  padding: 10px !important;
  border-radius: 0px;
  /* font-family: 'montserrat'; */

  transition: 0.2s all ease-in;
}

.scrollAbleAddressSelection .ant-list-item.addressItem {
  background: rgb(14, 131, 92);
  color: white !important;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  border-radius: 5px;
  /* cursor: 'pointer'  */
}
.shopInfo .scrollAbleAddressSelection .ant-list-item.addressItem .time {
  color: white;
}
.showMapLocation {
  border-radius: 2em;
  /* background: white; */
  /* color: black; */
  /* border: none; */
  z-index: 9;
}

.mapDetailModal .more-info {
  /* border:1px solid black; */
  /* display: flex; */
}

.mapDetailModal .more-info .street-view {
  text-align: center;
  /* flex:1; */
  padding: 10px;
  font-size: 0.8em;
}
.mapDetailModal .more-info .street-view img {
  width: 50px;
  height: 50px;
  padding: 10px;
  color: aquamarine;
}
.mapDetailModal .more-info .text-detail {
  /* border:1px solid black; */
  /* flex-basis: 70%; */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userPoint {
  /* height: 10px; */
  /* width: 10px; */
  /* margin-top: 20px; */
  /* margin-left: 30px; */
  /* position: relative; */
  background: #45453f;
  border: #45453f 1px solid;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.pulse {
  width: 10px;
  height: 10px;
  border: 6px solid rgb(0, 153, 255);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #716f42;
  z-index: 10;
  top: -6px;
  left: -6px;
  position: absolute;
}

.dot {
  border: 20px solid #0199ff;
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: pulse 3s ease-out;
  -moz-animation: pulse 3s ease-out;
  animation: pulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0;
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  25% {
    -moz-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes "pulse" {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

.pin {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg) translate(63%, -132%);
  z-index: 1;
}

.pin:after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  margin: 7px 0 0 7px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

.marker {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
  /* z-index: 1; */
}

.marker:after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  margin: 7px 0 0 7px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}
