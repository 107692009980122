.cartConatiner {
  margin: 20px;
  min-height: 400px;
  background: white;
  box-shadow: 3px 6px 16px rgba(221, 221, 221, 0.2);
  /* padding: 25px; */

  --padding-left: 60px 40px 30px 60px;
  /* font-family: montserrat; */
}

.cartInfo,
.payment {
  /* border:1px solid black; */
  min-height: 200px;
  padding: 20px;
}
.cartInfo {
  padding: var(--padding-left);
}
.cartInfo .cartAddress {
  margin-top: 30px;
}

.cartInfo .cross {
  text-decoration: line-through;
}

.cartDishList {
  /* margin-top: 56px; */
}
.cartDishItem {
  margin-top: 16px;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  display: flex;
}

.cartDishItem .dishImg {
  /* border:1px solid black; */
  flex-basis: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dishImg img {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
}

@media only screen and (max-width: 768px) {
  .cartItem .name,
  .cartItem .price,
  .cartItem .option,
  .cartItem .count {
    font-size: 12px !important;
    
  }
  .cartConatiner {
    --padding-left: 20px;
  }
}
.cartItem .option {
  display: flex;
  justify-content: right;
  align-items: center;
 
}

.cartCharges .cartItem .name,
.cartCharges .cartItem .price,
.cartCharges .cartItem .count {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: right;
  align-items: center;
  /* border:1px solid black; */
}
.cartItem .name {
  /* padding-left: 20px; */
  /* padding-right: 10px; */
  flex: 1;
  font-size: 1em;
  /* max-width: 40%; */
 

}
.comment {
  font-size: 0.9em;
  /* padding:8px 0px; */
  color: rgb(128, 128, 128);
}
.cartItem .name > div {
  /* border:1px solid red; */
}
.cartItem .price {
  flex-basis: 20%;
  flex-shrink: 0;

  text-align: right;
  justify-content: right;
}
.cartItem .option {
  flex-basis: 30px;

  text-align: right;
  justify-content: right;
}

.cartItem img {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  display: inline-block;
}
.cartCharges {
}

.cartCharges .name {
  padding-left: 0;
}

.cartCharges .cartItem {
  display: flex;
}
.cartCharges .cartItem .tipInfo {
  width: 100%;
  flex: 1;
  padding: 8px;
  margin: 10px 0px;
  font-size: 0.9em;
  color: grey;
  /* border:1px solid rgb(233, 233, 233); */

  text-align: right;
}
.cartCharges .cartItem .tipsSetting {
  margin-top: 5px;

  display: flex;

  align-content: center;
  justify-content: flex-end;
}

.tipInfo .confirmButton {
  /* border:1px solid black; */
  margin-top: 8px;
}
.cartCharges .cartItem .tipsSetting * {
  /* border-radius: 0px; */
}
.cartCharges .cartItem .tipsSetting button,
.cartCharges .cartItem .tipsSetting .tipVal {
  margin-left: 10px;
}

.payment {
  padding: 60px 30px 40px 60px;
  padding: var(--padding-left);
}

.payment .paymentArea {
  min-height: 170px;
}
.payment .paymentButton {
  margin-top: 20px;
}
.paymentButton .btn {
  height: 60px !important;
}

.braintree-heading {
  display: none;
}

.emptyButton {
  margin: 20px 0px;
  /* border-radius: 0px !important; */
  padding: 0px 30px;
  height: 40px;
  border-radius: 2em;
  font-size: 1em;
}

.notLogin {
  padding: 60px 30px 40px 60px;
  padding: var(--padding-left);
  /* border:1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notLogin .alertMsg {
  color: rgb(99, 99, 99);
  margin: 20px 0px;
}
.notLogin .loginbtn {
  border-radius: 0px;
  border: 1px solid black;
  width: 100%;
  padding: 16px 32px;
  background: rgb(24, 24, 24);
  height: auto;
  font-size: 0.9rem;
  margin-bottom: 10px;
}
.notLogin .signUpbtn {
  color: rgb(78, 78, 78);
  font-size: 0.8rem;
}

.cartItem .left {
  width: 50px;
}
.cartItem .right {
  padding-left: 8px;
  flex: 1;
  /* border:1px solid black; */
}

.right {
  display: flex;
  flex-direction: column;

  justify-content: center;
}
.right .row {
  display: flex;
}
.right .row.addon {
  font-size: 0.9em;
  padding-left: 5px;
}
.right .row .name {
  /* border:1px solid black; */
  flex: 1;
  flex-shrink: 0;
  display: flex;
}
.right .row .count {
  /* border:1px solid black; */
  display: flex;
  align-items: flex-end;
  flex-basis: 10%;
  /* flex:10%; */
  margin-right: 5px;
}
.right .row .price {
  flex-basis: 50px;
  /* border:1px solid red; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* text-align: right; */
}
.right .comment {
  text-transform: capitalize;
  padding: 3px 0px;
  color: rgb(161, 161, 161);
}

.right .row.main {
  font-size: 1em;
  /* font-weight: bold; */
  text-transform: capitalize;
}
.right .row.main .name {
  color: black;
}



.cartTime{
  /* border:1px solid black; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
