.shopsContainer {
  margin: 30px 10px;
  
  height: 100%;
 
  padding: 20px;
  
 
}
.text{
 
}
.shopCard {
  background: white;
  /* box-shadow: 3px 6px 16px rgba(221, 221, 221, 0.2); */
}
.shopInfo {
  padding-top: 10px;
  
}
.shopMap {
  padding: 10px;
}
.shopMap .locationImg {

}


.shopMap .options {
 
  min-height: 30px;
  margin-top: 20px;
}

.restaurantCard {
  min-height: 150px;
 
  margin-bottom: 16px;
  box-shadow: 3px 6px 16px rgba(109, 109, 109, 0.2);
  font-size: 1em;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  transition: 0.2s ease-in transform; 
}
.restaurantCard:hover{
 
  transform: scale(1.02);
  transition: 0.2s ease-in transform ; 
}


.restaurantCard .img{
  position: absolute;
 
  height: 100%;
  width: 100%;
  
   /* width: 100%;  */
  
}
.restaurantCard .img img {
  
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  height: 100%;
   width: 100%; 
  display: block;
}
.restaurantCard .info {
  
  position: absolute;

  padding: 16px;
  /* height: 100%; */
  background: rgba(32, 32, 32, 0.815);
  color:white;
  bottom: 0px;
  /* left: 0; */
  width: 100%;

}
.restaurantCard .info .name {
  padding: 3px 0px 2px 0px;
}

.restaurantCard .info .distance {
  font-size: 0.8em;
}




.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(240, 155, 155);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
