.checkout {
  /* border:1px solid black; */
}
.savedCards {
  /* border: 1px solid blue; */
  width: 100%;
  height: auto;
}

.savedCards .option {
  /* margin: 10px; */
  width: 100%;
  margin: 8px 0px;

  /* border:1px solid red; */
  background: rgb(248, 248, 248);
  padding: 10px 20px;

  /* box-shadow: 3px 6px 16px rgba(153, 153, 153, 0.2); */
}

.savedCards .option.newcard {
  margin-bottom: 0px;
  background: transparent;
  z-index: 9;
}

.savedCards .option .card img {
  height: 40px;
  width: 69px;
}

.cdInfo {
  background: white;
  padding: 10px 20px;
  /* background: black; */
  padding-top: 60px;
  transform: translateY(-40px);
  border: 1px solid rgb(173, 173, 173);
  border-radius: 10px;
}
.cdInfo:before {
}
