.statusBox {
  background: white;
  box-shadow: 3px 6px 16px rgba(221, 221, 221, 0.2);
  padding: 40px 20px;
}

.paymentInProgress {
}

.statusBox {
  /* border:1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.statusBox .statusImg img {
  height: 100px;
  width: 100px;
}

.status img {
  height: 150px;
  width: 150px;
}

.gotoOrderButton {
  margin: 20px;
}
.errorMsg {
  color: rgb(212, 11, 11);
  font-size: 1.2em;
}
.errorMsg .subtitle {
  color: black;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 20px;
}
.errorMsg .solution {
  color: rgb(49, 49, 49);
  margin: 20px;
  font-size: 0.8em;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: rgb(211, 211, 211);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
