.sellerOrders .orderItem{
padding:10px;
position: relative;

}



.sellerOrders .orderItem .mark{
  position: absolute;
  height: 100%;
  width:5px;
background: rgb(248, 66, 66);
left:0;

}
