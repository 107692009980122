.header {
  /* height: 80px; */
  width: 100%;
  background: white;
  /* background-color: #001529; */
  padding: 0px 10px;
  box-shadow: 0px 3px 16px rgba(221, 221, 221, 0.2);
}
.header_row {
  height: 64px;
  /* padding: 0px 1px !important; */
}

.title {
  height: 64px;
  overflow: hidden;
  font-size: 1rem;
}
.logoImg {
  /* height: 80%; */
  margin-right: 10px;
  width: 100%;
  max-width: 180px;
}

.ant-menu {
  background: transparent !important;
}
