.shopHeader{
  padding:20px;
 
}
.shopHeader .storeLogo{
 
  max-height: 100px;
  max-width: 100%;

}

.shopHeader .time{

/* padding-bottom: 18px; */
color: rgb(110, 110, 110);
}

.shopHeader .subtitle{
  font-size: 0.9em;
  padding-bottom: 8px;
  color: rgb(110, 110, 110);
}
