.dishModal {
  width: 70vw;
  max-width: 800px;
  font-weight: 500;
}
.dishModal .ant-modal-content {
  border-radius: 0px;
  /* width: 70vw; */
}
.dishModal .ant-modal-body {
  padding: 0px;
  min-height: 400px;
}

.dishModal .dishImg {
  /* position: absolute; */

  min-height: 400px;
  height: 100%;
  width: 100%;
  /* border:1px solid black; */
  object-fit: cover;
  object-position: center; /* Center the image within the element */
}

.dishModal .info .name {
  font-weight: 400;
  font-size: 1.6em;
}
.dishModal .info .price {
  padding-top: 5px;
  font-size: 1em;
}
.dishModal .ant-modal-footer {
  border-top: none;
}

.dishModal .buttons {
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  margin-top: 50px;
}
.dishModal .buttons .confirm {
  padding: 10px;
  height: auto;
}
.dishModal .buttons .cancel {
  padding: 10px;
  height: auto;
}

@media (max-width: 575px) {
  .dishModal.ant-modal {
    margin: 0 !important;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw !important;
  }
  .dishModal .ant-modal-content {
    /* height: 100vh; */
    min-height: 100vh;
    width: 100%;
  }

  .dishModal .dishImg {
    /* max-height: 300px; */
    height: 400px;
    /* object-fit: none;  */
    object-fit: cover;
    object-position: center; /* Center the image within the element */
  }
  .dishImg {
  }
}

.dishModal .rightContainer {
  display: flex;
  flex-direction: column;
}
.dishModal .rightContainer .infomation {
  flex: 1;
}
