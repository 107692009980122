.DistanceOrderAlert{
  margin-top: 20px;
}

.deliveryable{
  display: block;
  padding: 10px 20px;
  
  color: white;
}
.deliveryable.avaliable{
  background: #3B805F;
}
.deliveryable.unavaliable{
  /* background:black; */
  color:grey;
}


