.Footer {
  background: white;
  padding: 30px 50px 10px 50px;
  /*  */
  flex: 0 0 auto;
  /* font-weight: 700; */
  font-family: montserrat;
  font-weight: 500;
}
.Footer .options {
  /* border:1px solid black; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 16px;
}
.options a,
.options button {
  border: none;
  color: rgb(126, 126, 126);
  background: transparent !important;
  /* font-weight: bold; */
}

.footerMain .icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 15px 15px 15px;
}
.footerMain .icons a {
  margin: 0px 8px;
}

a#wechat {
  position: relative;
}
#wechat img {
  visibility: hidden;
  opacity: 0;
  transform: translate(0, 10px);
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: -30px;
  bottom: 40px;
  width: 150px;
  height: 150px;
}
#wechat:hover img {
  visibility: visible;
  transform: translate(0, 0px);
  opacity: 1;
}
