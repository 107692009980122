.login_title {
  /* border:1px solid black; */
  /* text-align: center; */
  padding: 20px;
  /* font-weight: 500; */
  font-size: 1.2rem;
  padding-left: 5px;
}

.login_form {
  padding: 20px 40px;
  box-shadow: 0px 3px 16px rgba(221, 221, 221, 0.2);
  border-radius: 10px;
  background: white;
  min-width: 300px;
}

@media only screen and (min-width: 768px) {
  .login_form {
    width: 500px;
    border: 1px solid balck;
  }
}

.phoneNUmber {
  padding: 0px 5px;
   width: 100%; 
  /* border-bottom: 1px solid rgba(175, 175, 175, 0.2); */
  height: 66px;
  line-height: 66px;
  /* border:1px solid black; */
}
.phoneNUmber label {
  /* margin-right: 10px; */
}
.phoneNUmber input {
  background: transparent;
  line-height: 1rem;
  border: 0px;
  outline: none;
}

.buttons {
  margin: 32px;
}
.signUp {
  /* border:1px solid black; */
  display: block;
  padding-bottom: 20px;
  font-size: 12px;
  color: darkgrey;
  text-align: center;
}
.signUp.only{
  padding-left: 5px;
  text-align: left;
  color:rgb(219, 113, 113);
  transition: color ease-in 0.3s;
}



input:disabled {
  background: #dddddd;
}

/* .ant-input-number-handler-wrap{
display: none;
}
.ant-input-group-addon{
   
    background: transparent;
    border:0px;
    
}
.ant-select.ant-select-enabled{
   width: auto !important;
    border:0px;
}
.ant-input{
    border:0px;
    outline: 0 none !important;
} */
