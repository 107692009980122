.ordersContainer {
}

.ordersContainer .imgs {
  width: 100%;

  white-space: nowrap;
  overflow: scroll;
}

.ordersContainer .line {
  display: flex;
  font-size: 0.9em;
}
.ordersContainer .line .text {
  flex-basis: 100px;
  flex-shrink: 0;
  margin-bottom: 8px;
}
.ordersContainer .line .numbers {
  flex: 1;
  text-align: right;
}
.ordersContainer .line .content {
  display: flex;
}

.ordersContainer .imgs img {
  width: 64px;
  height: 64px;
  margin-right: 20px;
  border-radius: 10px;
  margin-bottom: 20px;

  /* border:1px solid black; */
}
.ant-collapse-borderless {
  background: transparent;
}

.panelTitle {
  /* display: flex; */
}
.panelTitle .imagesAndStatus{
   
    font-size: 0.8em;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
   justify-content: center;
}
.panelTitle .images {
  
  /* border:1px solid black; */
  display: flex;
  align-items: center;
}
.panelTitle .status{
  padding-top: 4px;
  line-height: 100%;
}
.panelTitle .ago{
  padding-top: 4px;
  text-transform: lowercase;
  line-height: 100%;
  
}

.panelTitle .info {
  /* flex: 4; */
  /* flex-shrink: 0; */
  /* text-align: right; */
  font-size: 0.8em;
  
  margin: 0px;
  line-height: 100%;
  /* word-wrap: none; */
  /* width: 60%; */
  
}
.panelTitle .info .date{
  padding-top: 4px;
}

.panelTitle .images img {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  margin-right: 10px;
}
