.homepageContainer {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
}
.headingContainer {
}

.mainheader {
  /* border:5px solid yellow; */
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}
.mainheader .gallery {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width:80%; */
  /* border:1px solid black; */
  margin: 30px 0px;
}
.mainheader .gallery img {
  width: 64px;
  border-radius: 100%;
  margin: 10px;
  box-shadow: 0px 8px 10px rgba(221, 221, 221, 0.3);
  animation: show 1s ease-out;
}
@keyframes show {
  from {
    transform: translateY(-30px);
    opacity: 0.3;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slogan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 2em;
  /* border:1px solid black; */
  /* border: 1px solid black; */
}

.ant-input.searchInput {
  border-radius: 0px;
  height: 60px;
  padding: 20px;
  padding-right: 40px;

  font-size: 1.3em;
}
.autoComplete {
  position: relative;
}
.getLocation {
  z-index: 9;
  padding: 0px 16px;
  margin: 5px;
  height: 60px;
  line-height: 60px;
  position: absolute;
  top: 0px;
  right: 0px;
  /* border:1px solid black; */
  font-size: 1.3em;
}

.searchButton {
  border-radius: 0px;
  height: 60px;
  width: 100%;
}

.searchBar {
  margin-top: 27px;
}

.searchInput {
  position: relative;
  /* min-width: 300px; */
  /* max-width: 600px; */
}
.selectMode {
  height: 60px;
  width: 100%;
}
.selectMode .ant-select-selection.ant-select-selection--single {
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
}
.selectMode .ant-select-selection__rendered {
  text-align: center;
  height: 60px;

  line-height: 60px;
  font-size: 1.3em;
  border-radius: 0px;
}

.searchButton {
  /* margin-left: 20px; */
}

.autocompleteDropDown {
  position: absolute;
  z-index: 99;
  transform: translateY(5px);
  width: calc(100% - 10px);
  /* border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6; */
  box-shadow: 0px 8px 10px rgba(221, 221, 221, 0.2);
  border-radius: 0 0 2px 2px;
  background: #fff;
}

.suggestionItem {
  padding: 12px;
  text-align: left;
  background-color: #fafafa;
  cursor: pointer;
}

.suggestionItemActive {
  padding: 12px;
  text-align: left;
  /* background-color: #fafafa; */
  background-color: rgb(141, 152, 214);
  cursor: pointer;
}

.featureSection {
  font-weight: 700;
  position: relative;

  min-height: 200px;
  /* border:1px solid black; */
  background: white;
  /* padding: 20px 0px; */
  padding: 100px 30px;
  /* text-shadow: rgb(219, 219, 219) 1px 0 5px; */
}
.featureSection li {
  margin: 5px;
}
.featureSection .shuttleImg {
  position: absolute;
  top: 0;
  padding: 100px 0px;
  z-index: 0;
}
.featureSection .shuttleImg img {
  width: 400px;
  max-width: 100%;
}
/* 
.brand .content {
  padding-left: 20px;
}
.brand ol {
  padding: 10px 30px;
}

*/
.guideline,
.brand {
  padding: 50px;
}
/* .guideline {
  min-height: 100vh;
} */
.guideline .title,
.brand .title {
  padding: 20px;
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 30px;
  font-weight: 700;
}

.guide_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  margin: 10px;
  background: white;
  box-shadow: 0px 6px 10px rgba(235, 235, 235, 0.2);
  padding: 20px;
  height: 320px;
  border-radius: 20px;
}
.guide_card img {
  display: block;
  height: 200px;
  width: 200px;
}
.guide_card > div {
  text-align: center;
  /* max-height: 100px; */
  overflow: hidden;
}
